import { gql } from '@apollo/client';

const INSERT_VISITOR = gql`
mutation insertVisitor($on_conflict: visitors_on_conflict = {constraint: visitors_ip_key, update_columns: [last_seen]}, $object: visitors_insert_input!) {
  insert_visitors_one(object: $object, on_conflict: $on_conflict) {
    id
  }
}

`;
export default INSERT_VISITOR