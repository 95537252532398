import { gql } from '@apollo/client';

const GET_BOOKS = gql`
query getBooks {
  products(order_by: {type: desc}) {
    price
    created_at
    discount
    id
    name
    updated_at
    stock
    sku
    shortDescription
    fullDescription
    isActive
    type
  }
}
  `

export default GET_BOOKS;
