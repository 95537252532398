import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

//import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";

import { NhostAuthProvider } from "@nhost/react-auth";
import { auth } from "./utils/nhost";

import { NhostApolloProvider } from "@nhost/react-apollo";

ReactDOM.render(
    <NhostAuthProvider auth={auth}>
      <NhostApolloProvider
        auth={auth}
        gqlEndpoint="https://hasura-57462c6c.nhost.app/v1/graphql"
      >
        <App />
      </NhostApolloProvider>
    </NhostAuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
