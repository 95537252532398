import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { loadLanguages } from "redux-multilanguage";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { fetchProducts } from "./redux/actions/productActions";
import rootReducer from "./redux/reducers/rootReducer";

import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

import Routers from "./Routers";

import GET_BOOKS from "./graphql/getBooks.js";

import { useMutation, useQuery } from "@apollo/client";
import INSERT_VISITOR from "graphql/InsertVisitor"
import { useAuth } from "@nhost/react-auth";

const App = props => {
  const { signedIn } = useAuth();

  const {data, error, loading} = useQuery(GET_BOOKS)
  if(error)console.log(error)

  const [insertVisitor, {data: mutData, error: mutError}] = useMutation(INSERT_VISITOR)

  if(mutError)console.log(mutError)

  const getIp = () => {
    fetch('https://ipapi.co/json/')
    .then(response => response.json())
    .then(data =>{
      localStorage.setItem("city", data.city);
  insertVisitor({
    variables: {
      object: {
        ip: data.ip,
        city: data.city
      }
    }
  })}
)
    return 
  }
  
  useEffect(()=>{
  if(signedIn===false&& signedIn !== null)getIp()
  },[signedIn])


  //request("https://hasura-57462c6c.nhost.app/v1/graphql", GET_BOOKS).then(async data => { await store.dispatch(fetchProducts(data.products))}).catch((err)=>{})


  useEffect(() => {
    store.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });


  const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
  );
  if(data)store.dispatch(fetchProducts(data.products))

  return (
    <Provider store={store}>
        <ToastProvider placement='bottom-right'>
          <BreadcrumbsProvider>
            <Routers />
          </BreadcrumbsProvider>
        </ToastProvider>
    </Provider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default App;
